<template>
  <Hero :slideContent="heroSlides" />

  <section class="mt-10 max-w-screen-lg mx-auto pb-20 w-9/10">
    <p class="text-nasida-green text-lg text-justify">Nasarawa State is fast becoming a top investment destination and We are primarily set up as the State's holisitic investment services provider.</p>

    <div class="my-10 grid md:grid-cols-2 lg:grid-cols-2 gap-8">

      <!-- <div class="p-6 rounded-lg bg-pale-green">
        <div class="flex items-end space-x-6 mb-4">
          <div class="w-16 h-20">
            <img class="object-contain" src="../../assets/icons/money.svg">
          </div>
          <h1 class="text-5xl text-nasida-grey">$300m</h1>
        </div>
        <p class="md:text-sm text-justify text-nasida-grey">In Q2 of 2021, the State attracted over $300m in investments.</p>
      </div> -->

      <div class="p-6 rounded-lg bg-pale-green">
        <div class="flex items-end space-x-6 mb-4">
          <div class="w-16 h-20">
            <img class="object-contain" src="../../assets/icons/diamond.svg">
          </div>
          <h1 class="text-5xl text-nasida-grey">10+</h1>
        </div>
        <p class="md:text-sm text-justify text-nasida-grey">With over 10 naturally occurring solid minerals, Nasarawa State is the foremost solid minerals reserve in the Country.</p>
      </div>
      
      <div class="p-6 rounded-lg bg-pale-green">
        <div class="flex items-end space-x-6 mb-4">
          <div class="w-16 h-20">
            <img class="object-contain" src="../../assets/icons/factory.svg">
          </div>
          <h1 class="text-5xl text-nasida-grey">11th</h1>
        </div>
        <p class="md:text-sm text-justify text-nasida-grey">The World bank ranks Nasarawa State as the 11th most accessible state to start a business in Nigeria.</p>
      </div>

    </div>

    <div class="my-10">
      <div class="w-full md:w-9/10 lg:w-3/5 mx-auto mb-6 lg:mb-12">
        <div class="w-full relative h-full">
          <div class="indicator i-1 top-4">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-2 top-4">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-3 top-10">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-4 top-14">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-5 top-24">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-6 top-28">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-7 top-36">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-8 top-44">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-9 top-44">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-10 top-56">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-11 top-56">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-12 top-60">
            <img src="../../assets/icons/indicator.gif">
          </div>
          <div class="indicator i-13 top-60">
            <img src="../../assets/icons/indicator.gif">
          </div>
        </div>
        <img class="md:hidden" src="../../assets/images/map-static.svg">
        <img class="hidden md:block" src="../../assets/images/map-plain.svg">
      </div>
      <div class="text-justify">
        <p> <span class="text-nasida-green font-bold">Across Nasarawa State's 13 Local Governments</span>, NASIDA is championing innovative approaches to working with investors in developing opportunities that leverage on the State's unique geographic location, naturally occurring solid minerals, vastness and fertility of land, and mass urban opportunities, among others.</p>
        <br>
        <AnchorLink url="investment-opportunities" />
      </div>
    </div>
  </section>

  <section
    id="nasarawa-at-a-glance"
    class="max-w-screen-lg mx-auto pb-20 w-9/10"
  >
    <div class="text-nasida-green">
      <h1 class="text-4xl md:text-5xl mb-4"><b>Nasarawa</b> at a Glance</h1>
      <AnchorLink
        text="explore"
        url="investment-opportunities"
      />
    </div>
    <div class="grid md:grid-cols-3 mt-10 gap-10">
      <div class="fact">
        <div class="fact-image">
          <img
            class="object-contain"
            src="../../assets/icons/capital.svg"
          />
        </div>
        <div class="fact-text">
          <p class="font-semibold">Capital</p>
          <p>Lafia</p>
        </div>
      </div>
      <div class="fact">
        <div class="fact-image">
          <img
            class="object-contain"
            src="../../assets/icons/local-government-areas.svg"
          />
        </div>
        <div class="fact-text">
          <p class="font-semibold">Local Government Areas</p>
          <p>13</p>
        </div>
      </div>
      <div class="fact">
        <div class="fact-image">
          <img
            class="object-contain"
            src="../../assets/icons/population.svg"
          />
        </div>
        <div class="fact-text">
          <p class="font-semibold">Population</p>
          <p>2,679,433</p>
        </div>
      </div>
      <div class="fact">
        <div class="fact-image">
          <img
            class="object-contain"
            src="../../assets/icons/major-minerals.svg"
          />
        </div>
        <div class="fact-text">
          <p class="font-semibold">Major Minerals</p>
          <p>Barite, Coal, Clay, Lead-Zinc, Gemstones, Silica Sand, Iron Ore, Granite, Tantalite, Marble, Mica, Cassiterite and more</p>
        </div>
      </div>
      <div class="fact">
        <div class="fact-image">
          <img
            class="object-contain"
            src="../../assets/icons/vegetation.svg"
          />
        </div>
        <div class="fact-text">
          <p class="font-semibold">Vegetation</p>
          <p>Guinea Savannah</p>
        </div>
      </div>
      <div class="fact">
        <div class="fact-image">
          <img
            class="object-contain"
            src="../../assets/icons/maize-crops.svg"
          />
        </div>
        <div class="fact-text">
          <p class="font-semibold">Major Crops</p>
          <p>Yam, Maize, Cassava, Groundnut,Garden Eggs, Rice, Sorghum,
            Sugarcane, Pepper, Cowpea & more</p>
        </div>
      </div>
    </div>
  </section>

  <!-- Investment Opportunities -->
  <section
    id="investment-opportunities"
    class="max-w-screen-lg mx-auto pb-20 w-9/10"
  >
    <div class="text-nasida-green">
      <h1 class="text-4xl md:text-5xl mb-4"><b>Investment</b> Opportunities</h1>
      <AnchorLink
        text="explore"
        url="investment-opportunities"
      />
    </div>

    <div class="mt-10 space-y-6">
      <InvestmentOpportunityCard
        :image="images[0]"
        :link="'/sector/agriculture'"
      >
        <template v-slot:header>Agriculture</template>
        <template v-slot:body>
          There is undeniable potential for commercial farming, fishery
          development, and eco-conservation in Nasarawa State. Our focal point
          is...
        </template>
      </InvestmentOpportunityCard>
      <InvestmentOpportunityCard
        :image="images[1]"
        :lightBg="true"
        :link="'/sector/mining'"
      >
        <template v-slot:header>Mining</template>
        <template v-slot:body>
          Nasarawa state is a premier investment frontier that rests atop rich
          mineral beds., including coal, marble, and zinc. Currently, there...
        </template>
      </InvestmentOpportunityCard>
      <InvestmentOpportunityCard
        :image="images[2]"
        :link="'/sector/transportation'"
      >
        <template v-slot:header>Transportation </template>
        <template v-slot:body>
          We are creating an inclusive transport system that caters to the needs of the State's demographic....
        </template>
      </InvestmentOpportunityCard>
      <InvestmentOpportunityCard
        :image="images[3]"
        :lightBg="true"
        :link="'/sector/healthcare'"
      >
        <template v-slot:header>Healthcare</template>
        <template v-slot:body>
          The Nasarawa State Government has constructed Primary Healthcare
          centers in each of the 13 Local Government Areas...
        </template>
      </InvestmentOpportunityCard>
      <InvestmentOpportunityCard
        :image="images[4]"
        :link="'/sector/energy'"
      >
        <template v-slot:header>Energy</template>
        <template v-slot:body>
          Our accelerated urbanization and quickly expanding industry
          necessitate the development a formidable energy infrastructure in the
          state. We....
        </template>
      </InvestmentOpportunityCard>
      <InvestmentOpportunityCard
        :image="images[5]"
        :lightBg="true"
        :link="'/sector/infrastructure'"
      >
        <template v-slot:header>Infrastructure</template>
        <template v-slot:body>
          Physical infrastructure is a key strategic considerations for the diversification of Nasarawa State. We consider...
        </template>
      </InvestmentOpportunityCard>
    </div>
  </section>

  <!-- Latest News -->
  <section
    id="latest-news"
    class="max-w-screen-lg mx-auto pb-20 w-9/10"
    v-if="pressReleases"
  >
    <div class="text-nasida-green">
      <h1 class="text-4xl md:text-5xl mb-4"><b>Latest</b> News</h1>
      <AnchorLink
        text="explore"
        url="press-releases"
      />
    </div>
    <div class="grid md:grid-cols-3 md:gap-x-4 mt-10">
      <PressRelease
        v-for="pressRelease in pressReleases.slice(0, 3)"
        :key="pressRelease.id"
        :post="pressRelease"
      />
    </div>
  </section>

  <!-- Our Partners -->
  <section
    id="our-partners"
    class="max-w-screen-lg mx-auto pb-20 w-9/10"
  >
    <div class="text-nasida-green">
      <h1 class="text-4xl md:text-5xl mb-4">Our partners speak</h1>
      <AnchorLink
        text="We have been working with clients around the world"
        url="#"
        :showArrow="false"
      />
    </div>

    <div class="mt-10 grid md:grid-cols-3 gap-10">
      <div>
        <div class="rounded-lg partner-card p-4 text-center">
          <h1 class="text-2xl mb-2">Professionalism and efficiency</h1>
          <p class="text-sm text-nasida-grey">NASIDA has indeed set very high standards in transparency and the ease of doing business between the Public and Private sector.</p>
        </div>

        <div class="flex flex-col items-center mt-6">
          <p class="text-lg">Mohammed Yamusa Sulaiman</p>
          <p class="text-sm text-nasida-grey">CEO | ABS Blueprint Ltd</p>
        </div>
      </div>
      <div>
        <div class="rounded-lg partner-card p-4 text-center">
          <h1 class="text-2xl mb-2">A valuable & instrumental partner</h1>
          <p class="text-sm text-nasida-grey">NASIDA has been valuable & instrumental in facilitating key relationships and removing obstacles in building our first minigrids in Nasarawa state.</p>
        </div>

        <div class="flex flex-col items-center mt-6">
          <p class="text-lg">William Brent</p>
          <p class="text-sm text-nasida-grey">Husk Power Systems</p>
        </div>
      </div>
      <div>
        <div class="rounded-lg partner-card p-4 text-center">
          <h1 class="text-2xl mb-2">A Willing & innovative partner</h1>
          <p class="text-sm text-nasida-grey">NASIDA showed they were innovative and willing  to work hard in tackling problems while setting up our oxygen & distribution business in Orozo.</p>
        </div>

        <div class="flex flex-col items-center mt-6">
          <p class="text-lg">Temie Giwa-Tubosun</p>
          <p class="text-sm text-nasida-grey">CEO, Lifebank</p>
        </div>
      </div>
    </div>

  </section>

 <!-- Video Section -->
  <section
    id="latest-news"
    class="max-w-screen-lg mx-auto pb-20 w-9/10"
    >
    <!-- v-if="pressReleases" -->
    <div class="text-nasida-green">
      <h1 class="text-3xl md:text-3xl mb-4">How to Access the New Trade Grievances Redress Mechanism in Nasarawa.</h1>
      <!-- <AnchorLink
        text="explore"
        url="press-releases"
      /> -->
    </div>
    <div class="flex justify-center">
      <video 
        class="w-full md:w-4/5 rounded-lg"
        controls
        poster="/grm.mp4"
      >
        <source src="/grm.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </section>



  <!-- Leading Corporations -->
  <section
    id="corporation"
    class="max-w-screen-lg mx-auto pb-20 w-9/10"
    v-if="corporations"
  >
    <div class="text-nasida-green text-center">
      <h1 class="text-4xl md:text-5xl mb-4">Leading corporations<b> in the state </b></h1>
    </div>
    <splide
      class="splide mt-8 mx-auto w-10/12 hidden md:block"
      :options="options"
      :slides="corporations"
    >
      <splide-slide
        v-for="corporation in corporations"
        :key="corporation.id"
      >
        <a
          :href="corporation.website_url"
          target="_blank"
        >
          <img
            class="object-contain"
            :src="corporation.logo"
          />
        </a>
      </splide-slide>
    </splide>
    <splide
      class="splide mt-8 mx-auto w-10/12 block md:hidden"
      :options="mobileOptions"
      :slides="corporations"
    >
      <splide-slide
        v-for="corporation in corporations"
        :key="corporation.id"
      >
        <a
          :href="corporation.website_url"
          target="_blank"
        >
          <img
            class="object-contain"
            :src="corporation.logo"
          />
        </a>
      </splide-slide>
    </splide>
  </section>
</template>
<script src="./home.page.js"></script>
<style src="./home.page.css" scoped lang="css"></style>

